import { createReducer, on } from '@ngrx/store';
import { EstablishmentState } from './interfaces/establishment.state';
import {
  addPhotos,
  addPhotosFailure,
  addPhotosSuccess,
  createEstablishment,
  createEstablishmentFailure,
  createEstablishmentSuccess,
  loadEstablishment,
  loadEstablishmentFailure,
  loadEstablishmentSuccess,
  updateEstablishment,
  updateEstablishmentFailure,
  updateEstablishmentSuccess,
} from './establishment.actions';
import { initialStateMock } from '../shared/mocks/initial-state.mock';

export const establishmentReducer = createReducer(
  initialStateMock.establishmentState,
  on(
    loadEstablishment,
    (state): EstablishmentState => ({
      ...state,
      loadStatus: 'loading',
      loadError: undefined,
    })
  ),
  on(
    loadEstablishmentSuccess,
    (state, { data }): EstablishmentState => ({
      ...state,
      loadStatus: 'success',
      establishment: data,
      isDefined: !!data,
    })
  ),
  on(
    loadEstablishmentFailure,
    (state, { error }): EstablishmentState => ({
      ...state,
      loadStatus: 'error',
      loadError: error,
    })
  ),
  on(
    createEstablishment,
    (state): EstablishmentState => ({
      ...state,
      createStatus: 'loading',
      createError: undefined,
    })
  ),
  on(
    createEstablishmentSuccess,
    (state): EstablishmentState => ({
      ...state,
      createStatus: 'success',
    })
  ),
  on(
    createEstablishmentFailure,
    (state, { error }): EstablishmentState => ({
      ...state,
      createStatus: 'error',
      createError: error,
    })
  ),
  on(
    updateEstablishment,
    (state): EstablishmentState => ({
      ...state,
      updateStatus: 'loading',
      updateError: undefined,
    })
  ),
  on(
    updateEstablishmentSuccess,
    (state): EstablishmentState => ({
      ...state,
      updateStatus: 'success',
    })
  ),
  on(
    updateEstablishmentFailure,
    (state, { error }): EstablishmentState => ({
      ...state,
      updateStatus: 'error',
      updateError: error,
    })
  ),
  on(
    addPhotos,
    (state): EstablishmentState => ({
      ...state,
      addPhotosStatus: 'loading',
      addPhotosError: undefined,
    })
  ),
  on(
    addPhotosSuccess,
    (state): EstablishmentState => ({
      ...state,
      addPhotosStatus: 'success',
    })
  ),
  on(
    addPhotosFailure,
    (state, { error }): EstablishmentState => ({
      ...state,
      addPhotosStatus: 'error',
      addPhotosError: error,
    })
  )
);
