import { createReducer, on } from '@ngrx/store';
import { CalendarState } from './interfaces/calendar.state';
import {
  createAvailabilityTimeRange,
  createAvailabilityTimeRangeFailure,
  createAvailabilityTimeRangeSuccess,
  deleteAvailabilityTimeRange,
  deleteAvailabilityTimeRangeFailure,
  deleteAvailabilityTimeRangeSuccess,
  loadAvailabilityTimeRange,
  loadAvailabilityTimeRangeFailure,
  loadAvailabilityTimeRangeSuccess,
  updateAvailabilityTimeRange,
  updateAvailabilityTimeRangeFailure,
  updateAvailabilityTimeRangeSuccess,
} from './calendar.actions';

export const initialState: CalendarState = {
  events: [],
  createStatus: 'pending',
  deleteStatus: 'pending',
  loadStatus: 'pending',
  updateStatus: 'pending',
};

export const calendarReducer = createReducer(
  initialState,
  on(
    loadAvailabilityTimeRange,
    (state): CalendarState => ({
      ...state,
      loadStatus: 'loading',
      loadError: undefined,
    })
  ),
  on(
    loadAvailabilityTimeRangeSuccess,
    (state, { data }): CalendarState => ({
      ...state,
      loadStatus: 'success',
      events: data,
    })
  ),
  on(
    loadAvailabilityTimeRangeFailure,
    (state, { error }): CalendarState => ({
      ...state,
      loadStatus: 'error',
      loadError: error,
    })
  ),
  on(
    createAvailabilityTimeRange,
    (state): CalendarState => ({
      ...state,
      createStatus: 'loading',
      createError: undefined,
    })
  ),
  on(
    createAvailabilityTimeRangeSuccess,
    (state): CalendarState => ({
      ...state,
      createStatus: 'success',
    })
  ),
  on(
    createAvailabilityTimeRangeFailure,
    (state, { error }): CalendarState => ({
      ...state,
      createStatus: 'error',
      createError: error,
    })
  ),
  on(
    updateAvailabilityTimeRange,
    (state): CalendarState => ({
      ...state,
      updateStatus: 'loading',
      updateError: undefined,
    })
  ),
  on(
    updateAvailabilityTimeRangeSuccess,
    (state): CalendarState => ({
      ...state,
      updateStatus: 'success',
    })
  ),
  on(
    updateAvailabilityTimeRangeFailure,
    (state, { error }): CalendarState => ({
      ...state,
      updateStatus: 'error',
      updateError: error,
    })
  ),
  on(
    deleteAvailabilityTimeRange,
    (state): CalendarState => ({
      ...state,
      deleteStatus: 'loading',
      deleteError: undefined,
    })
  ),
  on(
    deleteAvailabilityTimeRangeSuccess,
    (state): CalendarState => ({
      ...state,
      deleteStatus: 'success',
    })
  ),
  on(
    deleteAvailabilityTimeRangeFailure,
    (state, { error }): CalendarState => ({
      ...state,
      deleteStatus: 'error',
      deleteError: error,
    })
  )
);
