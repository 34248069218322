import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import {
  addPhotos,
  addPhotosFailure,
  addPhotosSuccess,
  createEstablishment,
  createEstablishmentFailure,
  createEstablishmentSuccess,
  deletePhoto,
  deletePhotoFailure,
  deletePhotoSuccess,
  loadEstablishment,
  loadEstablishmentFailure,
  loadEstablishmentSuccess,
  updateEstablishment,
  updateEstablishmentFailure,
  updateEstablishmentSuccess,
} from './establishment.actions';
import { catchError, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { EstablishmentService } from './establishment.service';
import { Store } from '@ngrx/store';
import { selectUserState } from '../auth/auth.selectors';
import { AppState } from '../app-state';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class EstablishmentEffects {
  loadEstablishment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEstablishment),
      switchMap((action) =>
        this.establishmentService.loadEstablishment(action.id).pipe(
          map((establishment) =>
            loadEstablishmentSuccess({
              data: establishment,
            })
          ),
          catchError((error) => of(loadEstablishmentFailure({ error })))
        )
      )
    )
  );

  createEstablishment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createEstablishment),
      concatLatestFrom(() => this.store.select(selectUserState)),
      switchMap(([action, user]) => {
        if (!user) {
          return of(
            createEstablishmentFailure({ error: 'firebase.error.no_user' })
          );
        }

        return this.establishmentService
          .createEstablishment(user.id, action.createEstablishment)
          .pipe(
            map(() => createEstablishmentSuccess({ id: user.id })),
            catchError((error) => of(createEstablishmentFailure({ error })))
          );
      })
    )
  );

  updateEstablishment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEstablishment),
      concatLatestFrom(() => this.store.select(selectUserState)),
      switchMap(([action, user]) => {
        if (!user) {
          return of(
            updateEstablishmentFailure({ error: 'firebase.error.no_user' })
          );
        }

        return this.establishmentService
          .updateEstablishment(user.id, action.updateEstablishment)
          .pipe(
            map(() => updateEstablishmentSuccess({ id: user.id })),
            catchError((error) => of(updateEstablishmentFailure({ error })))
          );
      })
    )
  );

  addPhotos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addPhotos),
      switchMap((action) => {
        const photosObservable = forkJoin(
          action.photos.map((photo) =>
            this.establishmentService.addPhoto(action.establishmentId, photo)
          )
        );
        return photosObservable.pipe(
          map(() => addPhotosSuccess()),
          catchError((error) => of(addPhotosFailure({ error })))
        );
      })
    )
  );
  deletePhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePhoto),
      switchMap((action) =>
        this.establishmentService
          .removePhoto(action.establishmentId, action.photo)
          .pipe(
            map(() => deletePhotoSuccess()),
            catchError((error) => of(deletePhotoFailure({ error })))
          )
      )
    )
  );
  private readonly prefixTranslation = 'dashboard.establishment.notification.';
  deletePhotoSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deletePhotoSuccess),
        tap(() =>
          this.alertService
            .open(
              this.translateService.instant(
                this.prefixTranslation + 'photo-removed-success'
              ),
              { status: TuiNotification.Success }
            )
            .subscribe()
        )
      ),
    { dispatch: false }
  );
  deletePhotoFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deletePhotoFailure),
        tap(() =>
          this.alertService
            .open(
              this.translateService.instant(
                this.prefixTranslation + 'photo-removed-error'
              ),
              { status: TuiNotification.Error }
            )
            .subscribe()
        )
      ),
    { dispatch: false }
  );
  addPhotosSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addPhotosSuccess),
        tap(() =>
          this.alertService
            .open(
              this.translateService.instant(
                `${this.prefixTranslation}establishment-photos-added`
              ),
              {
                status: TuiNotification.Success,
              }
            )
            .subscribe()
        )
      ),
    { dispatch: false }
  );
  addPhotosFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addPhotosFailure),
        tap(() =>
          this.alertService
            .open(
              this.translateService.instant(
                `${this.prefixTranslation}establishment-photos-failed`
              ),
              {
                status: TuiNotification.Error,
              }
            )
            .subscribe()
        )
      ),
    { dispatch: false }
  );
  createEstablishmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createEstablishmentSuccess),
      tap(() =>
        this.alertService
          .open(
            this.translateService.instant(
              `${this.prefixTranslation}establishment-created`
            ),
            {
              status: TuiNotification.Success,
            }
          )
          .subscribe()
      ),
      map((action) => loadEstablishment({ id: action.id }))
    )
  );
  createEstablishmentFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createEstablishmentFailure),
        tap(() =>
          this.alertService
            .open(
              this.translateService.instant(
                `${this.prefixTranslation}establishment-created-error`
              ),
              {
                status: TuiNotification.Error,
              }
            )
            .subscribe()
        )
      ),
    { dispatch: false }
  );
  updateEstablishmentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateEstablishmentSuccess),
        tap(() =>
          this.alertService
            .open(
              this.translateService.instant(
                `${this.prefixTranslation}establishment-updated`
              ),
              {
                status: TuiNotification.Success,
              }
            )
            .subscribe()
        )
      ),
    { dispatch: false }
  );
  updateEstablishmentFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateEstablishmentFailure),
        tap(() =>
          this.alertService
            .open(
              this.translateService.instant(
                `${this.prefixTranslation}establishment-updated-error`
              ),
              {
                status: TuiNotification.Error,
              }
            )
            .subscribe()
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private readonly establishmentService: EstablishmentService,
    private readonly store: Store<AppState>,
    private readonly alertService: TuiAlertService,
    private readonly translateService: TranslateService
  ) {}
}
