import { createReducer, on } from '@ngrx/store';
import { OfferState } from './interfaces/offer.state';
import {
  createOffer,
  createOfferFailure,
  createOfferSuccess,
  deleteOffer,
  deleteOfferFailure,
  deleteOfferSuccess,
  loadOffers,
  loadOffersFailure,
  loadOffersSuccess,
  updateOffer,
  updateOfferFailure,
  updateOfferSuccess,
} from './offer.actions';

export const initialState: OfferState = {
  // additional entity state properties
  offers: [],
  loadStatus: 'pending',
  createStatus: 'pending',
  updateStatus: 'pending',
  deleteStatus: 'pending',
};

// TODO: complete with loading action

export const offerReducer = createReducer(
  initialState,
  on(
    loadOffers,
    (state): OfferState => ({
      ...state,
      loadStatus: 'loading',
      loadError: undefined,
    })
  ),
  on(
    loadOffersSuccess,
    (state, { offers }): OfferState => ({
      ...state,
      loadStatus: 'success',
      offers,
    })
  ),
  on(
    loadOffersFailure,
    (state, { error }): OfferState => ({
      ...state,
      loadStatus: 'error',
      loadError: error,
    })
  ),
  on(
    createOffer,
    (state): OfferState => ({
      ...state,
      createStatus: 'loading',
      createError: undefined,
    })
  ),
  on(
    createOfferSuccess,
    (state): OfferState => ({
      ...state,
      createStatus: 'success',
    })
  ),
  on(
    createOfferFailure,
    (state, { error }): OfferState => ({
      ...state,
      createStatus: 'error',
      createError: error,
    })
  ),
  on(
    updateOffer,
    (state): OfferState => ({
      ...state,
      updateStatus: 'loading',
      updateError: undefined,
    })
  ),
  on(
    updateOfferSuccess,
    (state): OfferState => ({
      ...state,
      updateStatus: 'success',
    })
  ),
  on(
    updateOfferFailure,
    (state, { error }): OfferState => ({
      ...state,
      updateStatus: 'error',
      updateError: error,
    })
  ),
  on(
    deleteOffer,
    (state): OfferState => ({
      ...state,
      deleteStatus: 'loading',
      deleteError: undefined,
    })
  ),
  on(
    deleteOfferSuccess,
    (state): OfferState => ({
      ...state,
      deleteStatus: 'success',
    })
  ),
  on(
    deleteOfferFailure,
    (state, { error }): OfferState => ({
      ...state,
      deleteStatus: 'error',
      deleteError: error,
    })
  )
);
