export enum CollectionEnum {
  ESTABLISHMENT = 'establishments',
  METRICS_FROM_ESTABLISHMENT = 'establishments/$id/metrics',
  OFFERS_FROM_ESTABLISHMENT = 'establishments/$id/packs',
  OFFER_DOC_FROM_ESTABLISHMENT = 'establishments/$id/packs/$packId',
  AVAILABILITIES_FROM_ESTABLISHMENT = 'establishments/$id/availabilities',
  AVAILABILITIES_DOC_FROM_ESTABLISHMENT = 'establishments/$id/availabilities/$timeId',
  VISITS_FROM_ESTABLISHMENT = 'establishments/$id/visits',
  VISITS_DOC_FROM_ESTABLISHMENT = 'establishments/$id/visits/$visitId',
}
