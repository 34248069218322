import { createAction, props } from '@ngrx/store';
import { EstablishmentInterface } from './interfaces/establishment.interface';
import { CreateEstablishmentInterface } from './interfaces/create-establishment.interface';
import { PhotoInterface } from '../shared/interfaces/photo.interface';

// region Load
export const loadEstablishment = createAction(
  '[Establishment] Load Establishment',
  props<{ id: string }>()
);

export const loadEstablishmentSuccess = createAction(
  '[Establishment] Load Establishment Success',
  props<{ data?: EstablishmentInterface }>()
);

export const loadEstablishmentFailure = createAction(
  '[Establishment] Load Establishment Failure',
  props<{ error: string }>()
);
// endregion

// region Create
export const createEstablishment = createAction(
  '[Establishment] Create Establishment',
  props<{ createEstablishment: CreateEstablishmentInterface }>()
);

export const createEstablishmentSuccess = createAction(
  '[Establishment] Create Establishment Success',
  props<{ id: string }>()
);

export const createEstablishmentFailure = createAction(
  '[Establishment] Create Establishment Failure',
  props<{ error: string }>()
);
// endregion

// region Update
export const updateEstablishment = createAction(
  '[Establishment] Update Establishment',
  props<{ updateEstablishment: CreateEstablishmentInterface }>()
);

export const updateEstablishmentSuccess = createAction(
  '[Establishment] Update Establishment Success',
  props<{ id: string }>()
);

export const updateEstablishmentFailure = createAction(
  '[Establishment] Update Establishment Failure',
  props<{ error: string }>()
);
// endregion

// region Add Photos
export const addPhotos = createAction(
  '[Establishment] Add photos',
  props<{ photos: File[]; establishmentId: string }>()
);

export const addPhotosSuccess = createAction(
  '[Establishment] Add photos success'
);

export const addPhotosFailure = createAction(
  '[Establishment] Add photos failure',
  props<{ error: string }>()
);

export const deletePhoto = createAction(
  '[Establishment] Delete photo',
  props<{ photo: PhotoInterface; establishmentId: string }>()
);

export const deletePhotoSuccess = createAction(
  '[Establishment] Delete photo success'
);

export const deletePhotoFailure = createAction(
  '[Establishment] Delete photo failure',
  props<{ error: string }>()
);
// endregion
