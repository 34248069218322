import { createAction, props } from '@ngrx/store';

import { CreateOfferInterface } from './interfaces/create-offer.interface';
import { OfferInterface } from './interfaces/offer.interface';

export const loadOffers = createAction('[Offer] Load Offers');

export const loadOffersSuccess = createAction(
  '[Offer] Load Offers success',
  props<{ offers: OfferInterface[] }>()
);

export const loadOffersFailure = createAction(
  '[Offer] Load Offers failure',
  props<{ error: string }>()
);

export const createOffer = createAction(
  '[Offer] Create Offer',
  props<{ createOffer: CreateOfferInterface; callbackSuccess?: () => void }>()
);

export const createOfferSuccess = createAction(
  '[Offer] Create Offer success',
  props<{ callbackSuccess?: () => void }>()
);

export const createOfferFailure = createAction(
  '[Offer] Create Offer failure',
  props<{ error: string }>()
);

export const updateOffer = createAction(
  '[Offer] Update Offer',
  props<{ idOffer: string; updateOffer: CreateOfferInterface }>()
);

export const updateOfferSuccess = createAction('[Offer] Update Offer success');

export const updateOfferFailure = createAction(
  '[Offer] Update Offer failure',
  props<{ error: string }>()
);

export const deleteOffer = createAction(
  '[Offer] Delete Offer',
  props<{ id: string }>()
);

export const deleteOfferSuccess = createAction(
  '[Offer] Delete Offer success',
  props<{ id: string }>()
);

export const deleteOfferFailure = createAction(
  '[Offer] Delete Offer failure',
  props<{ error: string }>()
);
