import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthenticationService } from './authentication.service';
import {
  isAuthenticated,
  loadAuth,
  loadAuthFailure,
  notAuthenticated,
  signIn,
  signInFailure,
  signInSuccess,
  signOut,
  signOutSuccess,
  signUp,
  signUpFailure,
  signUpSuccess,
  updatePassword,
  updatePasswordFailure,
  updatePasswordSuccess,
} from './auth.actions';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import { AppRoute } from '../../app.route';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import { TranslateService } from '@ngx-translate/core';
import { loadEstablishment } from '../establishment/establishment.actions';
import { loadOffers } from '../offers/offer.actions';
import { loadAvailabilityTimeRange } from '../calendar/calendar.actions';
import { loadVisits } from '../visits/visit.actions';

@Injectable()
export class AuthEffects {
  loadAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAuth),
      switchMap(() =>
        this.authService.getUser().pipe(
          switchMap(async (user) => {
            if (!!user) {
              const stripeRole = await this.authService.getStripeRole(user);
              return isAuthenticated({
                userData: {
                  displayName: user.displayName,
                  email: user.email,
                  id: user.uid,
                  stripeRole,
                },
              });
            }

            return notAuthenticated();
          }),
          catchError((err) => of(loadAuthFailure({ error: err.toString() })))
        )
      )
    )
  );

  userAuthenticatedWithSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(isAuthenticated),
      mergeMap((action) => [
        loadEstablishment({ id: action.userData.id }),
        loadOffers(),
        loadAvailabilityTimeRange({ idEstablishment: action.userData.id }),
        loadVisits({ idEstablishment: action.userData.id }),
      ])
    )
  );

  userNotAuthenticated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notAuthenticated),
        tap(() => this.router.navigate([AppRoute.AUTHENTICATION]))
      ),
    { dispatch: false }
  );

  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signIn),
      switchMap((action) =>
        this.authService.login(action.email, action.password).pipe(
          map(() => signInSuccess()),
          catchError((error) =>
            of(
              signInFailure({
                error: this.authService.transformAuthError(error),
              })
            )
          )
        )
      )
    )
  );

  signInSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signInSuccess),
      tap(() => this.router.navigate([AppRoute.DASHBOARD])),
      map(() => loadAuth())
    )
  );

  signInFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signInFailure),
        tap((action) => {
          const error = this.translateService.instant(action.error);
          this.alertService
            .open(error, {
              status: TuiNotification.Error,
            })
            .subscribe();
        })
      ),
    { dispatch: false }
  );

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUp),
      switchMap((action) =>
        this.authService.createUser(action.email, action.password).pipe(
          map(() =>
            signUpSuccess({
              firstName: action.firstName,
              lastName: action.lastName,
            })
          ),
          catchError((error) =>
            of(
              signUpFailure({
                error: this.authService.transformAuthError(error),
              })
            )
          )
        )
      )
    )
  );

  signUpSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpSuccess),
      switchMap((action) =>
        this.authService
          .updateDisplayName(action.firstName, action.lastName)
          .pipe(map(() => loadAuth()))
      )
    )
  );

  signUpFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signUpFailure),
        tap((action) => {
          const error = this.translateService.instant(action.error);
          this.alertService
            .open(error, {
              status: TuiNotification.Error,
            })
            .subscribe();
        })
      ),
    { dispatch: false }
  );

  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signOut),
      switchMap(() =>
        this.authService.signOut().pipe(map(() => signOutSuccess()))
      )
    )
  );

  signOutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signOutSuccess),
        tap(() => this.router.navigate([AppRoute.AUTHENTICATION]))
      ),
    { dispatch: false }
  );

  updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePassword),
      switchMap((action) =>
        this.authService
          .updatePassword(action.actualPassword, action.newPassword)
          .pipe(
            map(() => updatePasswordSuccess()),
            catchError((error) => of(updatePasswordFailure({ error })))
          )
      )
    )
  );
  readonly prefixTranslation = 'auth.notification.';
  updatePasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updatePasswordSuccess),
        tap(() =>
          this.alertService
            .open(
              this.translateService.instant(
                `${this.prefixTranslation}password-updated`
              ),
              {
                status: TuiNotification.Success,
              }
            )
            .subscribe()
        )
      ),
    { dispatch: false }
  );
  updatePasswordFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updatePasswordFailure),
        tap(({ error }) =>
          this.alertService
            .open(
              this.translateService.instant(
                `${this.prefixTranslation}password-updated-error`
              ),
              {
                status: TuiNotification.Success,
              }
            )
            .subscribe()
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly alertService: TuiAlertService,
    private readonly translateService: TranslateService
  ) {}
}
