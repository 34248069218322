import { AppState } from '../../app-state';

export const initialStateMock: AppState = {
  authState: {
    status: 'pending',
    statusSignUp: 'pending',
    statusSignIn: 'pending',
  },
  establishmentState: {
    isDefined: false,
    loadStatus: 'pending',
    updateStatus: 'pending',
    createStatus: 'pending',
    addPhotosStatus: 'pending',
  },
  offerState: {
    loadStatus: 'pending',
    createStatus: 'pending',
    updateStatus: 'pending',
    deleteStatus: 'pending',
    offers: [],
  },
  calendarState: {
    events: [],
    loadStatus: 'pending',
    createStatus: 'pending',
    updateStatus: 'pending',
    deleteStatus: 'pending',
  },
  visitState: {
    loadVisit: 'pending',
    cancelVisit: 'pending',
    passed: [],
    future: [],
  },
};
