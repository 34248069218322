// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentInterface } from './interfaces/environment.interface';

export const environment: EnvironmentInterface = {
  firebase: {
    projectId: 'rd-wine-dev',
    appId: '1:137106445614:web:4dd85a8513f76bc99f56bf',
    storageBucket: 'rd-wine-dev.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyAoJNx0RKg190jru8JTUraR2-1EpqBk2XQ',
    authDomain: 'rd-wine-dev.firebaseapp.com',
    messagingSenderId: '137106445614',
    measurementId: 'G-FF0NHW67RB',
  },
  production: false,
  name: 'development',
  useEmulators: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
