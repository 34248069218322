import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoute } from './app.route';
import { AuthPremiumGuard } from './modules/shared/guards/auth-premium.guard';

const routes: Routes = [
  {
    path: AppRoute.AUTHENTICATION,
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: AppRoute.DASHBOARD,
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthPremiumGuard],
  },
  {
    path: '**',
    redirectTo: AppRoute.DASHBOARD,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
