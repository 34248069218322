<tui-root>
  <!-- content of your app -->
  <div *ngIf="isNotLoading(loadUserStatus$ | async); else loading" class="min-h-screen w-screen">
    <router-outlet></router-outlet>
  </div>

  <ng-template #loading>
    <div class="flex min-h-screen w-screen justify-center items-center">
      <app-global-loader></app-global-loader>
    </div>
  </ng-template>

  <ng-container ngProjectAs="tuiOverContent">
    <!-- Content over app content -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverDialogs">
    <!-- Content over dialogs -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverAlerts">
    <!-- Content over notifications -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverPortals">
    <!-- Content over dropdowns -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverHints">
    <!-- Content over hints -->
  </ng-container>
</tui-root>
