import { createReducer, on } from '@ngrx/store';
import { AuthState } from './interfaces/auth-state';
import {
  isAuthenticated,
  loadAuth,
  loadAuthFailure,
  notAuthenticated,
  signIn,
  signInFailure,
  signInSuccess,
  signOutSuccess,
  signUp,
  signUpFailure,
  signUpSuccess,
} from './auth.actions';

export const initialState: AuthState = {
  user: undefined,
  status: 'pending',
  statusSignIn: 'pending',
  statusSignUp: 'pending',
};

export const authReducer = createReducer(
  initialState,
  on(
    loadAuth,
    (state): AuthState => ({
      ...state,
      status: 'loading',
      error: undefined,
    })
  ),
  on(
    isAuthenticated,
    (state, { userData }): AuthState => ({
      ...state,
      user: userData,
      status: 'success',
    })
  ),
  on(
    notAuthenticated,
    (state): AuthState => ({ ...state, user: undefined, status: 'success' })
  ),
  on(
    loadAuthFailure,
    (state, { error }): AuthState => ({ ...state, status: 'error', error })
  ),
  on(
    signIn,
    (state): AuthState => ({
      ...state,
      statusSignIn: 'loading',
      errorSignIn: undefined,
    })
  ),
  on(
    signInSuccess,
    (state): AuthState => ({ ...state, statusSignIn: 'success' })
  ),
  on(
    signInFailure,
    (state, { error }): AuthState => ({
      ...state,
      errorSignIn: error,
      statusSignIn: 'error',
    })
  ),
  on(
    signUp,
    (state): AuthState => ({
      ...state,
      statusSignUp: 'loading',
      errorSignUp: undefined,
    })
  ),
  on(
    signUpSuccess,
    (state): AuthState => ({ ...state, statusSignUp: 'success' })
  ),
  on(
    signUpFailure,
    (state, { error }): AuthState => ({
      ...state,
      statusSignUp: 'error',
      errorSignUp: error,
    })
  ),
  on(
    signOutSuccess,
    (state): AuthState => ({
      ...state,
      user: undefined,
    })
  )
);
