import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  CollectionReference,
  Firestore,
} from '@angular/fire/firestore';
import { CollectionEnum } from '../shared/enums/collection.enum';
import { Observable } from 'rxjs';
import { VisitInterface } from './interfaces/visit.interface';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { CancelVisitRequestDto } from './dto/cancel-visit-request.dto';
import { CancelVisitResponseDto } from './dto/cancel-visit-response.dto';
import { Auth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class VisitService {
  private cancelVisitCallable = httpsCallableData<
    CancelVisitRequestDto,
    CancelVisitResponseDto
  >(this.functions, 'cancelReservedSlots');

  constructor(
    private readonly firestore: Firestore,
    private readonly functions: Functions,
    private readonly auth: Auth
  ) {}

  loadVisits(idEstablishment: string): Observable<VisitInterface[]> {
    return collectionData(this.getCollectionReference(idEstablishment), {
      idField: 'id',
    }) as Observable<VisitInterface[]>;
  }

  cancelVisit(visit: VisitInterface) {
    const establishmentId = this.auth.currentUser?.uid;
    return this.cancelVisitCallable({
      eventId: visit.id,
      clientId: '',
      establishmentId: establishmentId!,
    });
  }

  private getCollectionReference(idEstablishment: string): CollectionReference {
    return collection(
      this.firestore,
      CollectionEnum.VISITS_FROM_ESTABLISHMENT.replace('$id', idEstablishment)
    );
  }
}
