import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, switchMap, take } from 'rxjs';
import { Auth, authState, getIdTokenResult } from '@angular/fire/auth';
import { TuiAlertService } from '@taiga-ui/core';
import { AuthErrorUrlType } from '../../auth/types/auth-error-url.type';

@Injectable({
  providedIn: 'root',
})
export class AuthPremiumGuard implements CanActivate {
  constructor(
    private readonly auth: Auth,
    private readonly alertService: TuiAlertService,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return authState(this.auth).pipe(
      take(1),
      switchMap(async (user) => {
        if (!user) {
          const error: AuthErrorUrlType = 'not-authenticated';
          return this.router.createUrlTree(['/auth', 'sign-in'], {
            queryParams: { error },
          });
        }

        const token = await getIdTokenResult(user, true);
        if (token.claims['stripeRole'] !== 'premium') {
          const error: AuthErrorUrlType = 'no-rights';
          return this.router.createUrlTree(['/auth', 'sign-in'], {
            queryParams: { error },
          });
        }

        return true;
      })
    );
  }
}
