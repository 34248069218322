import { createAction, props } from '@ngrx/store';
import { AvailabilityInterface } from './interfaces/availability.interface';

export const loadAvailabilityTimeRange = createAction(
  '[Calendar] Load AvailabilityTimeRange',
  props<{ idEstablishment: string }>()
);

export const loadAvailabilityTimeRangeSuccess = createAction(
  '[Calendar] Load AvailabilityTimeRange Success',
  props<{ data: AvailabilityInterface[] }>()
);

export const loadAvailabilityTimeRangeFailure = createAction(
  '[Calendar] Load AvailabilityTimeRange Failure',
  props<{ error: string }>()
);

export const createAvailabilityTimeRange = createAction(
  '[Calendar] Create AvailabilityTimeRange',
  props<{
    data: AvailabilityInterface;
    idEstablishment: string;
    configurationEndDate: Date;
    callbackSuccess?: () => void;
  }>()
);

export const createAvailabilityTimeRangeSuccess = createAction(
  '[Calendar] Create AvailabilityTimeRange Success',
  props<{ callbackSuccess?: () => void }>()
);

export const createAvailabilityTimeRangeFailure = createAction(
  '[Calendar] Create AvailabilityTimeRange Failure',
  props<{ error: string }>()
);

export const updateAvailabilityTimeRange = createAction(
  '[Calendar] Update AvailabilityTimeRange',
  props<{
    data: AvailabilityInterface;
    idEstablishment: string;
  }>()
);

export const updateAvailabilityTimeRangeSuccess = createAction(
  '[Calendar] Update AvailabilityTimeRange Success'
);

export const updateAvailabilityTimeRangeFailure = createAction(
  '[Calendar] Update AvailabilityTimeRange Failure',
  props<{ error: string }>()
);

export const deleteAvailabilityTimeRange = createAction(
  '[Calendar] Delete AvailabilityTimeRange',
  props<{ idAvailabilityTimeRange: string; idEstablishment: string }>()
);

export const deleteAvailabilityTimeRangeSuccess = createAction(
  '[Calendar] Delete AvailabilityTimeRange Success'
);

export const deleteAvailabilityTimeRangeFailure = createAction(
  '[Calendar] Delete AvailabilityTimeRange Failure',
  props<{ error: string }>()
);
