import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc,
  Firestore,
  updateDoc,
} from '@angular/fire/firestore';
import {
  ref,
  Storage,
  StorageReference,
  uploadBytes,
} from '@angular/fire/storage';
import { CollectionEnum } from '../shared/enums/collection.enum';
import { StorageEnum } from '../shared/enums/storage.enum';
import { from, Observable, tap } from 'rxjs';
import { OfferInterface } from './interfaces/offer.interface';
import { CreateOfferInterface } from './interfaces/create-offer.interface';

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  constructor(
    private readonly firestore: Firestore,
    private readonly storage: Storage
  ) {}

  loadOffers(idEstablishment: string): Observable<OfferInterface[]> {
    return collectionData(this.getCollectionReference(idEstablishment), {
      idField: 'id',
    }) as Observable<OfferInterface[]>;
  }

  createOffer(idEstablishment: string, createOffer: CreateOfferInterface) {
    const { photo } = createOffer;
    const offer = this.getOfferFromCreation(createOffer);

    if (!photo) {
      return from(addDoc(this.getCollectionReference(idEstablishment), offer));
    }

    return from(
      addDoc(this.getCollectionReference(idEstablishment), offer)
    ).pipe(
      tap((documentReference) =>
        this.addPhoto(idEstablishment, documentReference.id, photo)
      )
    );
  }

  updateOffer(
    idEstablishment: string,
    idOffer: string,
    createOffer: CreateOfferInterface
  ) {
    const offer = this.getOfferFromCreation(createOffer);
    const { photo } = createOffer;

    if (!photo) {
      return from(
        updateDoc(this.getOfferDocumentReference(idEstablishment, idOffer), {
          ...offer,
        })
      );
    }

    return from(
      updateDoc(this.getOfferDocumentReference(idEstablishment, idOffer), {
        ...offer,
      })
    ).pipe(tap(() => this.addPhoto(idEstablishment, idOffer, photo)));
  }

  deleteOffer(establishmentId: string, offerId: string) {
    return from(
      deleteDoc(this.getOfferDocumentReference(establishmentId, offerId))
    );
  }

  private getOfferFromCreation(
    createOffer: CreateOfferInterface
  ): OfferInterface {
    const { description, duration, maxPeople, minPeople, name, price } =
      createOffer;

    return {
      name,
      description,
      duration,
      maxPeople,
      minPeople,
      price,
    };
  }

  private addPhoto(idEstablishment: string, idOffer: string, photo: File) {
    return from(
      uploadBytes(
        this.getStorageReferenceFile(idEstablishment, idOffer, photo.name),
        photo
      )
    );
  }

  private getCollectionReference(idEstablishment: string): CollectionReference {
    return collection(
      this.firestore,
      CollectionEnum.OFFERS_FROM_ESTABLISHMENT.replace('$id', idEstablishment)
    );
  }

  private getOfferDocumentReference(idEstablishment: string, idOffer: string) {
    return doc(
      this.firestore,
      CollectionEnum.OFFER_DOC_FROM_ESTABLISHMENT.replace(
        '$id',
        idEstablishment
      ).replace('$packId', idOffer)
    );
  }

  private getStorageReferenceFile(
    idEstablishment: string,
    idOffer: string,
    fileName: string
  ): StorageReference {
    return ref(
      this.storage,
      `${StorageEnum.OFFERS_FROM_ESTABLISHMENT_PHOTOS.replace(
        '$id',
        idEstablishment
      ).replace('$packId', idOffer)}/${fileName}`
    );
  }
}
