import { createSelector } from '@ngrx/store';
import { AppState } from '../app-state';

export const authStateSelector = (state: AppState) => state.authState;
export const loadUserStatusSelector = createSelector(
  authStateSelector,
  (state) => state.status
);
export const selectUserState = createSelector(
  authStateSelector,
  (state) => state.user
);
export const signInStatusSelector = createSelector(
  authStateSelector,
  (state) => state.statusSignIn
);
export const signInErrorSelector = createSelector(
  authStateSelector,
  (state) => state.errorSignIn
);

export const signUpStatusSelector = createSelector(
  authStateSelector,
  (state) => state.statusSignUp
);
export const signUpErrorSelector = createSelector(
  authStateSelector,
  (state) => state.errorSignUp
);
