import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { OfferService } from './offer.service';
import {
  createOffer,
  createOfferFailure,
  createOfferSuccess,
  deleteOffer,
  deleteOfferFailure,
  deleteOfferSuccess,
  loadOffers,
  loadOffersFailure,
  loadOffersSuccess,
  updateOffer,
  updateOfferFailure,
  updateOfferSuccess,
} from './offer.actions';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { selectUserState } from '../auth/auth.selectors';
import { TranslateService } from '@ngx-translate/core';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';

@Injectable()
export class OfferEffects {
  loadOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOffers),
      concatLatestFrom(() => this.store.select(selectUserState)),
      switchMap(([, userState]) =>
        this.offerService.loadOffers(userState!.id).pipe(
          map((offers) => loadOffersSuccess({ offers })),
          catchError((error) => of(loadOffersFailure({ error })))
        )
      )
    )
  );

  createOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOffer),
      concatLatestFrom(() => this.store.select(selectUserState)),
      switchMap(([action, userState]) =>
        this.offerService.createOffer(userState!.id, action.createOffer).pipe(
          map(() =>
            createOfferSuccess({ callbackSuccess: action.callbackSuccess })
          ),
          catchError((error) => of(createOfferFailure({ error })))
        )
      )
    )
  );
  updateOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOffer),
      concatLatestFrom(() => this.store.select(selectUserState)),
      switchMap(([action, userState]) =>
        this.offerService
          .updateOffer(userState!.id, action.idOffer, action.updateOffer)
          .pipe(
            map(() => updateOfferSuccess()),
            catchError((error) => of(updateOfferFailure({ error })))
          )
      )
    )
  );
  deleteOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteOffer),
      concatLatestFrom(() => this.store.select(selectUserState)),
      switchMap(([action, userState]) =>
        this.offerService.deleteOffer(userState!.id, action.id).pipe(
          map(() => deleteOfferSuccess({ id: action.id })),
          catchError((error) => of(deleteOfferFailure({ error })))
        )
      )
    )
  );
  readonly prefixTranslation = 'dashboard.offers.notification.';
  createOfferSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createOfferSuccess),
        tap((action) => {
          !!action.callbackSuccess && action.callbackSuccess();
          this.alertService
            .open(
              this.translateService.instant(
                `${this.prefixTranslation}offer-created`
              ),
              {
                status: TuiNotification.Success,
              }
            )
            .subscribe();
        })
      ),
    { dispatch: false }
  );
  createOfferFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createOfferFailure),
        tap(() =>
          this.alertService
            .open(
              this.translateService.instant(
                `${this.prefixTranslation}offer-created-error`
              ),
              {
                status: TuiNotification.Error,
              }
            )
            .subscribe()
        )
      ),
    { dispatch: false }
  );
  updateOfferSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateOfferSuccess),
        tap(() =>
          this.alertService
            .open(
              this.translateService.instant(
                `${this.prefixTranslation}offer-updated`
              ),
              {
                status: TuiNotification.Success,
              }
            )
            .subscribe()
        )
      ),
    { dispatch: false }
  );
  updateOfferFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateOfferFailure),
        tap(() =>
          this.alertService
            .open(
              this.translateService.instant(
                `${this.prefixTranslation}offer-updated-error`
              ),
              {
                status: TuiNotification.Error,
              }
            )
            .subscribe()
        )
      ),
    { dispatch: false }
  );
  deleteOfferSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteOfferSuccess),
        tap(() =>
          this.alertService
            .open(
              this.translateService.instant(
                `${this.prefixTranslation}offer-deleted`
              ),
              {
                status: TuiNotification.Success,
              }
            )
            .subscribe()
        )
      ),
    { dispatch: false }
  );

  deleteOfferFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteOfferFailure),
        tap(() =>
          this.alertService
            .open(
              this.translateService.instant(
                `${this.prefixTranslation}offer-deleted-error`
              ),
              {
                status: TuiNotification.Error,
              }
            )
            .subscribe()
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private readonly offerService: OfferService,
    private readonly store: Store<AppState>,
    private readonly translateService: TranslateService,
    private readonly alertService: TuiAlertService
  ) {}
}
