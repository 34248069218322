import { createAction, props } from '@ngrx/store';
import { VisitInterface } from './interfaces/visit.interface';

export const loadVisits = createAction(
  '[Visit] Load Visits',
  props<{ idEstablishment: string }>()
);

export const loadVisitsSuccess = createAction(
  '[Visit] Load Visits Success',
  props<{ data: VisitInterface[] }>()
);

export const loadVisitsFailure = createAction(
  '[Visit] Load Visits Failure',
  props<{ error: string }>()
);
