import { createAction, props } from '@ngrx/store';
import { UserInterface } from './interfaces/user.interface';

export const loadAuth = createAction('[Auth] Load Auths');

export const isAuthenticated = createAction(
  '[Auth] Authenticated',
  props<{ userData: UserInterface }>()
);

export const notAuthenticated = createAction('[Auth] Not authenticated');

export const loadAuthFailure = createAction(
  '[Auth] Load Auths Failure',
  props<{ error: string }>()
);

export const signIn = createAction(
  '[Auth] Sign in',
  props<{ email: string; password: string }>()
);
export const signInSuccess = createAction('[Auth] Sign in success');
export const signInFailure = createAction(
  '[Auth] Sign in failure',
  props<{ error: string }>()
);

export const signUp = createAction(
  '[Auth] Sign up',
  props<{
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }>()
);
export const signUpSuccess = createAction(
  '[Auth] Sign up success',
  props<{ firstName: string; lastName: string }>()
);
export const signUpFailure = createAction(
  '[Auth] Sign up failure',
  props<{ error: string }>()
);
export const signOut = createAction('[Auth] Sign out');
export const signOutSuccess = createAction('[Auth] Sign out success');

export const updatePassword = createAction(
  '[Auth] Update password',
  props<{ actualPassword: string; newPassword: string }>()
);
export const updatePasswordSuccess = createAction(
  '[Auth] Update password success'
);
export const updatePasswordFailure = createAction(
  '[Auth] Update password failure',
  props<{ error: string }>()
);
