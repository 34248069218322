import { createReducer, on } from '@ngrx/store';
import { VisitState } from './interfaces/visit.state';
import {
  loadVisits,
  loadVisitsFailure,
  loadVisitsSuccess,
} from './visit.actions';
import { isAfter, isBefore } from 'date-fns';

export const initialState: VisitState = {
  future: [],
  passed: [],
  cancelVisit: 'pending',
  loadVisit: 'pending',
};

export const visitReducer = createReducer(
  initialState,
  on(
    loadVisits,
    (state): VisitState => ({
      ...state,
      loadVisit: 'loading',
      loadError: undefined,
    })
  ),
  on(
    loadVisitsSuccess,
    (state, { data }): VisitState => ({
      ...state,
      future: data
        .filter((elem) => isAfter(elem.start.toDate(), new Date()))
        .sort((a, b) => a.start.toMillis() - b.start.toMillis()),
      passed: data
        .filter((elem) => isBefore(elem.start.toDate(), new Date()))
        .sort((a, b) => b.start.toMillis() - a.start.toMillis()),
      loadVisit: 'success',
    })
  ),
  on(
    loadVisitsFailure,
    (state, { error }): VisitState => ({
      ...state,
      loadVisit: 'error',
      loadError: error,
    })
  )
);
