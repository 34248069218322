import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  CollectionReference,
  doc,
  docData,
  Firestore,
  GeoPoint,
  setDoc,
  updateDoc,
} from '@angular/fire/firestore';
import { CollectionEnum } from '../shared/enums/collection.enum';
import { EstablishmentInterface } from './interfaces/establishment.interface';
import { from, map, Observable, switchMap, take, tap } from 'rxjs';
import { CreateEstablishmentInterface } from './interfaces/create-establishment.interface';
import {
  deleteObject,
  ref,
  Storage,
  StorageReference,
  uploadBytes,
  UploadResult,
} from '@angular/fire/storage';
import { PhotoInterface } from '../shared/interfaces/photo.interface';
import { DocumentData } from '@angular/fire/compat/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { CheckStripeAccountRequestDto } from './dto/check-stripe-account-request.dto';
import { CheckStripeAccountResponseDto } from './dto/check-stripe-account-response.dto';
import { EstablishmentMetricInterface } from './interfaces/establishment-metric.interface';
import { getAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class EstablishmentService {
  readonly collectionReference: CollectionReference;
  readonly storageReference: StorageReference;
  readonly checkStripeAccountIsConfigured = httpsCallableData<
    CheckStripeAccountRequestDto,
    CheckStripeAccountResponseDto
  >(this.functions, 'establishmentmanager-checkStripeAccountIsConfigured');

  constructor(
    private readonly firestore: Firestore,
    private readonly storage: Storage,
    private readonly functions: Functions
  ) {
    this.collectionReference = collection(
      this.firestore,
      CollectionEnum.ESTABLISHMENT
    );

    this.storageReference = ref(this.storage, '/establishments/');
  }

  loadEstablishment(
    idEstablishment: string
  ): Observable<EstablishmentInterface> {
    return docData(doc(this.collectionReference, idEstablishment), {
      idField: 'id',
    }).pipe(
      switchMap((e) => {
        const establishment = e as EstablishmentInterface;
        return this.checkStripeAccountIsConfigured({
          stripeAccount: establishment.stripeAccountId || '',
        }).pipe(
          map((res) => ({
            ...establishment,
            isStripeAccountConfigure: res.isStripeAccountConfigure,
          }))
        );
      })
    ) as Observable<EstablishmentInterface>;
  }

  createEstablishment(
    idEstablishment: string,
    createEstablishment: CreateEstablishmentInterface
  ) {
    const {
      address,
      description,
      displayPhoneNumber,
      geohash,
      languages,
      latitude,
      longitude,
      name,
      phoneNumber,
      vineyardLocation,
    } = createEstablishment;

    const data: EstablishmentInterface = {
      name,
      description,
      phoneNumber,
      geohash,
      languages,
      displayPhoneNumber,
      address,
      active: false,
      photos: [],
      localization: new GeoPoint(latitude, longitude),
      vineyardLocation,
    };
    return from(setDoc(doc(this.collectionReference, idEstablishment), data));
  }

  getEstablishmentMetrics(): Observable<EstablishmentMetricInterface[]> {
    return collectionData(
      collection(
        this.firestore,
        CollectionEnum.METRICS_FROM_ESTABLISHMENT.replace(
          '$id',
          getAuth().currentUser!.uid!
        )
      ),
      {
        idField: 'id',
      }
    ) as Observable<EstablishmentMetricInterface[]>;
  }

  updateEstablishment(
    idEstablishment: string,
    createEstablishment: CreateEstablishmentInterface
  ) {
    const {
      address,
      description,
      displayPhoneNumber,
      geohash,
      languages,
      latitude,
      longitude,
      name,
      phoneNumber,
      vineyardLocation,
    } = createEstablishment;

    const data: Partial<EstablishmentInterface> = {
      name,
      description,
      phoneNumber,
      geohash,
      languages,
      displayPhoneNumber,
      address,
      localization: new GeoPoint(latitude, longitude),
      vineyardLocation,
    };
    return from(
      updateDoc(doc(this.collectionReference, idEstablishment), data)
    );
  }

  addPhoto(idEstablishment: string, file: File): Observable<UploadResult> {
    return from(
      uploadBytes(
        ref(this.storageReference, `${idEstablishment}/${file.name}`),
        file
      )
    );
  }

  removePhoto(
    idEstablishment: string,
    photo: PhotoInterface
  ): Observable<DocumentData> {
    const documentDataDocumentReference = doc(
      this.collectionReference,
      idEstablishment
    );
    return docData(documentDataDocumentReference).pipe(
      take(1),
      tap((data) => {
        const photos: PhotoInterface[] = data['photos'];
        return updateDoc(documentDataDocumentReference, {
          photos: photos.filter((elem) => elem.src !== photo.src),
        });
      }),
      tap(() => deleteObject(ref(this.storage, photo.src)))
    );
  }
}
