import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadVisits,
  loadVisitsFailure,
  loadVisitsSuccess,
} from './visit.actions';
import { VisitService } from './visit.service';
import { catchError, map, of, switchMap } from 'rxjs';

@Injectable()
export class VisitEffects {
  loadVisits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadVisits),
      switchMap((action) =>
        this.visitService.loadVisits(action.idEstablishment).pipe(
          map((data) => loadVisitsSuccess({ data })),
          catchError((error) => of(loadVisitsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private readonly visitService: VisitService
  ) {}
}
