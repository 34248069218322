import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc,
  Firestore,
  Timestamp,
} from '@angular/fire/firestore';
import { CollectionEnum } from '../shared/enums/collection.enum';
import { combineLatest, from, map, Observable } from 'rxjs';
import { AvailabilityInterface } from './interfaces/availability.interface';
import { EventFrequencyType } from './types/event-frequency.type';
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  isBefore,
  isSaturday,
  isSunday,
} from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private readonly firestore: Firestore) {}

  loadAvailabilityTimeRange(
    idEstablishment: string
  ): Observable<AvailabilityInterface[]> {
    return collectionData(this.getCollectionReference(idEstablishment), {
      idField: 'id',
    }).pipe(
      map((availabilities) =>
        availabilities.map((element) => ({
          id: element['id'],
          title: element['title'],
          meta: element['meta'],
          start: (element['start'] as Timestamp).toDate(),
          end: (element['end'] as Timestamp).toDate(),
        }))
      )
    );
  }

  createAvailabilities(
    availability: AvailabilityInterface,
    configurationEndDate: Date,
    idEstablishment: string
  ): Observable<unknown> {
    const availabilities = this.generateListOfAvailabilityByFrequency(
      availability,
      configurationEndDate
    );

    const observables: Observable<unknown>[] = availabilities.map((element) =>
      from(addDoc(this.getCollectionReference(idEstablishment), element))
    );

    return combineLatest(observables);
  }

  deleteAvailability(
    idAvailabilityTimeRange: string,
    idEstablishment: string
  ): Observable<void> {
    return from(
      deleteDoc(
        this.getAvailabilityTimeRangeDocumentReference(
          idEstablishment,
          idAvailabilityTimeRange
        )
      )
    );
  }

  private generateListOfAvailabilityByFrequency(
    availability: AvailabilityInterface,
    configurationEndDate: Date
  ): AvailabilityInterface[] {
    const frequencyId = uuidv4();

    const availabilities: AvailabilityInterface[] = [
      { ...availability, meta: { ...availability.meta, frequencyId } },
    ];

    if (availability.meta.frequency === 'no') {
      return availabilities;
    }

    let { start, end } = {
      start: this.getNextDateDependingOnFrequency(
        availability.meta.frequency,
        availability.start
      ),
      end: this.getNextDateDependingOnFrequency(
        availability.meta.frequency,
        availability.end!
      ),
    };

    while (isBefore(start, configurationEndDate)) {
      availabilities.push({
        ...availability,
        start,
        end,
        meta: { ...availability.meta, frequencyId },
      });
      start = this.getNextDateDependingOnFrequency(
        availability.meta.frequency,
        start
      );
      end = this.getNextDateDependingOnFrequency(
        availability.meta.frequency,
        end
      );
    }

    return availabilities;
  }

  private getNextDateDependingOnFrequency(
    frequency: EventFrequencyType,
    currentDate: Date
  ): Date {
    switch (frequency) {
      case 'no':
        return currentDate;
      case 'day':
        return addDays(currentDate, 1);
      case 'week-day':
        let nextDate = addDays(currentDate, 1);
        if (isSaturday(nextDate)) {
          nextDate = addDays(nextDate, 2);
        } else if (isSunday(nextDate)) {
          nextDate = addDays(currentDate, 1);
        }
        return nextDate;
      case 'week':
        return addWeeks(currentDate, 1);
      case 'month':
        return addMonths(currentDate, 1);
      case 'year':
        return addYears(currentDate, 1);
    }
  }

  private getCollectionReference(idEstablishment: string): CollectionReference {
    return collection(
      this.firestore,
      CollectionEnum.AVAILABILITIES_FROM_ESTABLISHMENT.replace(
        '$id',
        idEstablishment
      )
    );
  }

  private getAvailabilityTimeRangeDocumentReference(
    idEstablishment: string,
    idAvailabilityTimeRange: string
  ) {
    return doc(
      this.firestore,
      CollectionEnum.AVAILABILITIES_DOC_FROM_ESTABLISHMENT.replace(
        '$id',
        idEstablishment
      ).replace('$timeId', idAvailabilityTimeRange)
    );
  }
}
