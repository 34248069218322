import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { CalendarService } from './calendar.service';
import {
  createAvailabilityTimeRange,
  createAvailabilityTimeRangeFailure,
  createAvailabilityTimeRangeSuccess,
  deleteAvailabilityTimeRange,
  deleteAvailabilityTimeRangeFailure,
  deleteAvailabilityTimeRangeSuccess,
  loadAvailabilityTimeRange,
  loadAvailabilityTimeRangeFailure,
  loadAvailabilityTimeRangeSuccess,
} from './calendar.actions';
import { TranslateService } from '@ngx-translate/core';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';

@Injectable()
export class CalendarEffects {
  readonly prefixTranslation = 'dashboard.calendar.notification.';

  loadAvailabilityTimeRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAvailabilityTimeRange),
      switchMap((action) =>
        this.calendarService
          .loadAvailabilityTimeRange(action.idEstablishment)
          .pipe(
            map((availabilities) =>
              loadAvailabilityTimeRangeSuccess({ data: availabilities })
            ),
            catchError((error) =>
              of(loadAvailabilityTimeRangeFailure({ error }))
            )
          )
      )
    )
  );

  createAvailabilityTimeRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAvailabilityTimeRange),
      switchMap((action) =>
        this.calendarService
          .createAvailabilities(
            action.data,
            action.configurationEndDate,
            action.idEstablishment
          )
          .pipe(
            map(() =>
              createAvailabilityTimeRangeSuccess({
                callbackSuccess: action.callbackSuccess,
              })
            ),
            catchError((error) =>
              of(createAvailabilityTimeRangeFailure({ error }))
            )
          )
      )
    )
  );

  createAvailabilityTimeRangeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createAvailabilityTimeRangeSuccess),
        tap((action) => {
          !!action.callbackSuccess && action.callbackSuccess();
          this.alertService
            .open(
              this.translateService.instant(
                this.prefixTranslation + 'create-availability'
              ),
              { status: TuiNotification.Success }
            )
            .subscribe();
        })
      ),
    { dispatch: false }
  );

  deleteAvailabilityTimeRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAvailabilityTimeRange),
      switchMap((action) =>
        this.calendarService
          .deleteAvailability(
            action.idAvailabilityTimeRange,
            action.idEstablishment
          )
          .pipe(
            map(() => deleteAvailabilityTimeRangeSuccess()),
            catchError((error) =>
              of(deleteAvailabilityTimeRangeFailure({ error }))
            )
          )
      )
    )
  );

  deleteAvailabilityTimeRangeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteAvailabilityTimeRangeSuccess),
        tap(() => {
          this.alertService
            .open(
              this.translateService.instant(
                this.prefixTranslation + 'delete-availability'
              ),
              { status: TuiNotification.Success }
            )
            .subscribe();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private readonly calendarService: CalendarService,
    private readonly translateService: TranslateService,
    private readonly alertService: TuiAlertService
  ) {}
}
