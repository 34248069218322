import { Injectable } from '@angular/core';
import {
  Auth,
  AuthError,
  authState,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getIdTokenResult,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  updateProfile,
  User,
  UserCredential,
} from '@angular/fire/auth';
import {
  catchError,
  EMPTY,
  from,
  map,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private readonly auth: Auth) {}

  getUser(): Observable<User | null> {
    return authState(this.auth);
  }

  async getStripeRole(user: User): Promise<string | object | undefined> {
    const idTokenResult = await getIdTokenResult(user, true);
    return idTokenResult.claims['stripeRole'];
  }

  createUser(email: string, password: string): Observable<UserCredential> {
    return from(createUserWithEmailAndPassword(this.auth, email, password));
  }

  login(email: string, password: string): Observable<UserCredential> {
    return from(signInWithEmailAndPassword(this.auth, email, password));
  }

  updateDisplayName(firstName: string, lastName: string): Observable<void> {
    return this.getUser().pipe(
      switchMap((user) => {
        if (!!user) {
          return from(
            updateProfile(user, {
              displayName: `${lastName} ${firstName}`,
            })
          );
        }

        return EMPTY;
      })
    );
  }

  signOut(): Observable<void> {
    return from(signOut(this.auth));
  }

  updatePassword(
    actualPassword: string,
    newPassword: string
  ): Observable<string> {
    const currentUser = this.auth.currentUser;
    const email = currentUser?.email;

    if (!!currentUser && !!email) {
      return from(
        reauthenticateWithCredential(
          currentUser,
          EmailAuthProvider.credential(email, actualPassword)
        )
      ).pipe(
        switchMap((user) =>
          from(updatePassword(user.user, newPassword)).pipe(
            map(() => 'UPDATED'),
            catchError(() => of('WRONG_PASSWORD'))
          )
        )
      );
    }

    return throwError(() => 'NOT_AUTHENTICATED');
  }

  transformAuthError(error: Error | AuthError) {
    if ('code' in error && error.code) {
      return `firebase.error.${error.code}`;
    }

    return 'general.error.standard';
  }
}
